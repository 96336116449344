<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.name="{ item }">
          <span class="form-link" @click="open(item)">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description ? item.description : "-" }}
        </template>
        <template v-slot:item.template="{ item }">
          {{ item.template ? item.template : "No Template" }}
        </template>
        <template v-slot:item.url="{ item }">
          {{
            item.url.length > 50 ? item.url.substring(0, 47) + "..." : item.url
          }}
        </template>
        <template v-slot:item.url_full="{ item }">
          {{ item.url ? item.url : "-" }}
        </template>
        <template v-slot:item.variable="{ item }">
          <div
            v-for="(apivariable, nameOfVariable, index) in item.apivariable"
            :key="apivariable.id"
          >
            {{ index === 0 ? nameOfVariable + "=" + apivariable + "..." : "" }}
          </div>
          <span v-if="item.apivariable.length === 0">-</span>
        </template>
        <template v-slot:item.variable_full="{ item }">
          <div
            v-for="(apivariable, nameOfVariable) in item.apivariable"
            :key="apivariable.id"
          >
            {{ nameOfVariable + "=" + apivariable }}
          </div>
          <span v-if="item.apivariable.length === 0">-</span>
        </template>
        <template v-slot:item.delete="{ item }">
          <v-menu
            v-model="item.deleteMenu"
            :close-on-content-click="false"
            rounded
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                v-if="item.deletable"
                color="bad-2"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon x-small>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list width="350">
                <v-list-item>
                  <v-list-item-content class="pb-0">
                    <v-list-item-title
                      >Are you sure you want to delete this ApiCall?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right mt-2">
                      <v-btn
                        rounded
                        text
                        color="tertiary"
                        small
                        class="text-capitalize"
                        @click="cancelDelete(item)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        text
                        color="bad-2"
                        small
                        class="text-capitalize"
                        @click="deleteApiCall(item)"
                      >
                        Delete
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <!--NAME-->
                    <td
                      v-if="expandItem.value === 'name'"
                      width="150"
                      class="pl-3"
                    >
                      Name:
                    </td>
                    <td v-if="expandItem.value === 'name'">
                      <span class="form-link" @click="open(item)">
                        {{ item.name }}
                      </span>
                    </td>
                    <!--END NAME-->

                    <!--DESCRIPTION-->
                    <td
                      v-if="expandItem.value === 'description'"
                      width="150"
                      class="pl-3"
                    >
                      Description:
                    </td>
                    <td v-if="expandItem.value === 'description'">
                      {{ item.description ? item.description : "-" }}
                    </td>
                    <!--END DESCRIPTION-->

                    <!--TEMPLATE-->
                    <td
                      v-if="expandItem.value === 'template'"
                      width="150"
                      class="pl-3"
                    >
                      Template:
                    </td>
                    <td v-if="expandItem.value === 'template'">
                      {{ item.template ? item.template : "No Template" }}
                    </td>
                    <!--END TEMPLATE-->

                    <!--URL-->
                    <td
                      v-if="expandItem.value === 'url'"
                      width="150"
                      class="pl-3"
                    >
                      Url short:
                    </td>
                    <td v-if="expandItem.value === 'url'">
                      {{
                        item.url.length > 50
                          ? item.url.substring(0, 47) + "..."
                          : item.url
                      }}
                    </td>
                    <!--END URL-->

                    <!--VARIABLE-->
                    <td
                      v-if="expandItem.value === 'variable'"
                      width="150"
                      class="pl-3"
                    >
                      Variable short:
                    </td>
                    <td v-if="expandItem.value === 'variable'">
                      <div
                        v-for="(
                          apivariable, nameOfVariable, index
                        ) in item.apivariable"
                        :key="apivariable.id"
                      >
                        {{
                          index === 0
                            ? nameOfVariable + "=" + apivariable + "..."
                            : ""
                        }}
                      </div>
                      <span v-if="item.apivariable.length === 0">-</span>
                    </td>
                    <!--END VARIABLE-->

                    <!--DELETE-->
                    <td
                      v-if="expandItem.value === 'delete'"
                      width="150"
                      class="pl-3"
                    >
                      Delete:
                    </td>
                    <td v-if="expandItem.value === 'delete'">
                      <v-menu
                        v-model="item.deleteMenu"
                        :close-on-content-click="false"
                        rounded
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-small
                            icon
                            v-if="item.deletable"
                            color="bad-2"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon x-small>fas fa-trash-alt</v-icon>
                          </v-btn>
                        </template>

                        <v-card>
                          <v-list width="350">
                            <v-list-item>
                              <v-list-item-content class="pb-0">
                                <v-list-item-title
                                  >Are you sure you want to delete this ApiCall?
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-right mt-2">
                                  <v-btn
                                    rounded
                                    text
                                    color="tertiary"
                                    small
                                    class="text-capitalize"
                                    @click="cancelDelete(item)"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    rounded
                                    text
                                    color="bad-2"
                                    small
                                    class="text-capitalize"
                                    @click="deleteApiCall(item)"
                                  >
                                    Delete
                                  </v-btn>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </td>
                    <!--END DELETE-->

                    <!--URL-->
                    <td
                      v-if="expandItem.value === 'url'"
                      width="150"
                      class="pl-3"
                    >
                      Url:
                    </td>
                    <td v-if="expandItem.value === 'url'">
                      {{ item.url ? item.url : "-" }}
                    </td>
                    <!--END URL-->

                    <!--VERSION-->
                    <td
                      v-if="expandItem.value === 'version'"
                      width="150"
                      class="pl-3"
                    >
                      Version:
                    </td>
                    <td v-if="expandItem.value === 'version'">
                      {{ item.version ? item.version : "-" }}
                    </td>
                    <!--END VERSION-->

                    <!--DATA-->
                    <td
                      v-if="expandItem.value === 'data'"
                      width="150"
                      class="pl-3"
                    >
                      Data:
                    </td>
                    <td v-if="expandItem.value === 'data'">
                      {{ item.data ? item.data : "-" }}
                    </td>
                    <!--END DATA-->

                    <!--VARIABLE-->
                    <td
                      v-if="expandItem.value === 'variable_full'"
                      width="150"
                      class="pl-3"
                    >
                      Variable:
                    </td>
                    <td v-if="expandItem.value === 'variable_full'">
                      <div
                        v-for="(
                          apivariable, nameOfVariable
                        ) in item.apivariable"
                        :key="apivariable.id"
                      >
                        {{ nameOfVariable + "=" + apivariable }}
                      </div>
                      <span v-if="item.apivariable.length === 0">-</span>
                    </td>
                    <!--END VARIABLE-->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],

      page: 1,
      pageTotalCount: 1,
      size: 1,

      sizes: [5, 10, 25, 50, 100, 200],
      deleteMenu: false,
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue !== undefined &&
          newValue !== this.sizes.indexOf(this.tableSize)
        ) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
  },
  methods: {
    statusColor(status) {
      if (status) {
        return "#87d05a";
      } else {
        return "#e53355";
      }
    },
    open(item) {
      this.idAddForm = item.id;
      if (this.$route.name === "apiCustom") {
        this.keywordAddForm = "apicallCustom";
      } else {
        this.keywordAddForm = "apicall";
      }
    },
    cancelDelete(item) {
      this.$emit("cancelDelete", item);
    },
    deleteApiCall(item) {
      this.$emit("deleteApiCall", item);
    },
  },
};
</script>

<style scoped></style>
